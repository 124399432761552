import type { IMessageChannelPortSender } from '@embeddables/messaging';
import debug from 'debug';

const dAudio = debug('widget:audio-system');

const isURLPayload = (message: unknown): boolean => {
  if (message && typeof message === 'object') {
    // eslint-disable-next-line
    // @ts-ignore
    if ('url' in message && typeof message.url === 'string') {
      return true;
    }
  }
  return false;
};

type InstallAudioMessage = { url: string };
type PlayAudioMessage = { url: string };

const isPlayAudioMessage = (message: unknown): message is PlayAudioMessage => isURLPayload(message);
const isInstallAudioMessage = (message: unknown): message is InstallAudioMessage => isURLPayload(message);

export const initAudioHost = (channel: IMessageChannelPortSender) => {
  const AUDIO_CACHE: Map<string, HTMLAudioElement> = new Map();

  dAudio('Initializing audio host');

  channel.addMessageListener('install-audio', async (data) => {
    if (isInstallAudioMessage(data)) {
      if (AUDIO_CACHE.has(data.url)) {
        dAudio(`Audio "${data.url}" is already in the cache.`);
        return;
      } else {
        dAudio(`Registering audio ${data.url}`);

        const audio = new Audio(data.url);
        AUDIO_CACHE.set(data.url, audio);
      }
    }
  });

  channel.addMessageListener('play-audio', async (data) => {
    if (isPlayAudioMessage(data)) {
      const audio = AUDIO_CACHE.get(data.url);

      if (!audio) {
        dAudio(`Audio "${data.url}" is not present on the cache.`);
        return Promise.reject(`Cannot play audio with key "${data.url}", it is not installed on the host.`);
      }

      dAudio(`Now playing "${data.url}"`);

      return audio.play().catch((error) => {
        dAudio(`Playing "${data.url}" failed`);

        const message =
          error instanceof Error ? error.message : 'Failed to play audio, non-error value returned from play promise.';

        return Promise.reject(message);
      });
    }
  });
};
