import initWidget, { InitParams } from './widget-script';

declare global {
  interface Window {
    iFoodWidget: {
      // Resolved after Iframe code is downloaded and global JS is running
      init(params: InitParams): Promise<void>;
      ready: Promise<void>;
      show(): void;
      hide(): void;
    };
  }
}

window.iFoodWidget = window.iFoodWidget || initWidget();
