export type MessageHandler = (body: unknown) => Promise<unknown>;

export type PendingMessage = [resolve: (data: unknown) => void, reject: (error: unknown) => void];

export enum Status {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum MessageType {
  REQUEST = 'REQUEST',
  RESPONSE = 'RESPONSE',
}

export interface IMessage {
  key: string;
  data: unknown;
  id: number;
  type: MessageType;
}

export interface IRequest extends IMessage {
  type: MessageType.REQUEST;
}

export interface IResponse extends IMessage {
  status: Status;
  type: MessageType.RESPONSE;
}
