export const MERCHANT_API_URL = 'https://merchant-api.ifood.com.br' as const;

export const WIDGET_URL = 'https://embeddables.ifood.com.br' as const;

export const SENDBIRD_APP_ID = 'EF74AA6B-497E-4F58-ADF3-75E76BAE9D37' as const;

export const GA_TRACKING_ID = 'UA-222214600-3' as const;

export const ENABLE_MSW = false;

export const MQTT_BROKER_URL = 'wss://firefly-api.ifood.com.br:443' as const;

export const MQTT_AUTHORIZER_URL = 'https://merchant-api.ifood.com.br/firefly/v1.0/oauth/token-exchange' as const;

export const EMBEDDABLES_APPLICATION_HOST = 'https://embeddables.ifood.com.br';

export const FASTER_KEY = '8e541982-d1d7-41e8-ac27-c4bbd8faa984' as const;

export const IS_PRODUCTION = true;

export const GOOGLE_MAPS_KEY = process.env.NX_GOOGLE_MAPS_KEY;

export const CHANGELOG_URL = 'https://developer.ifood.com.br/embeddables-changelog' as const;

export const ENABLE_FASTER_MOCK = false;

export const NX_USER_PILOT_ID = process.env.NX_USER_PILOT_ID as string;

export const HISTORY_API_URL = 'https://merchant-api.ifood.com.br/firefly/merchant/v1.0/history';

export const SENTRY_DSN = process.env.NX_SENTRY_DSN;

export const SENTRY_ENVIRONMENT = 'production';
