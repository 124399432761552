import { WIDGET_URL } from '@embeddables/env';
import { CommandFrame, createHitRegionManager } from '@embeddables/hit-region-host';
import { IMessageChannelPortSender } from '@embeddables/messaging';
import { initAudioHost } from '@embeddables/shared/audio-host';

const version = '1.0.1';

const setupStyles = (): HTMLStyleElement => {
  const styles = document.createElement('style');

  styles.innerHTML = `
    .embeddables-iframe {
      border: none;
      background: transparent;
    }
  `;

  return styles;
};

const setPlacement = (placement: string, element: HTMLElement): void => {
  switch (placement) {
    case 'BOTTOM_RIGHT':
      element.style.setProperty('top', 'unset');
      element.style.setProperty('left', 'unset');
      element.style.setProperty('right', '0px');
      element.style.setProperty('bottom', '0px');
      break;
    case 'BOTTOM_LEFT':
      element.style.setProperty('top', 'unset');
      element.style.setProperty('left', '0px');
      element.style.setProperty('right', 'unset');
      element.style.setProperty('bottom', '0px');
      break;
    case 'TOP_RIGHT':
      element.style.setProperty('top', '0px');
      element.style.setProperty('left', 'unset');
      element.style.setProperty('right', '0px');
      element.style.setProperty('bottom', 'unset');
      break;
    case 'TOP_LEFT':
      element.style.setProperty('top', '0px');
      element.style.setProperty('left', '0px');
      element.style.setProperty('right', 'unset');
      element.style.setProperty('bottom', 'unset');
      break;

    default:
      console.error(`Invalid widget placement ${placement}`);
      break;
  }
};

const setupIFrame = (): HTMLIFrameElement => {
  const frame = document.createElement('iframe');
  frame.className = 'embeddables-iframe';

  const src = new URL(WIDGET_URL);
  src.searchParams.set('origin', window.origin);
  frame.src = src.href;

  return frame;
};

type DeferredPromiseBuild<T> = [
  Promise<T>,
  {
    resolver: (value: T | PromiseLike<T>) => void;
    rejecter: (reason?: unknown) => void;
  }
];

const deferredPromise = <T = void>(): DeferredPromiseBuild<T> => {
  let resolver: (value: T | PromiseLike<T>) => void;
  let rejecter: (reason?: unknown) => void;

  const promise = new Promise<T>((resolve, reject) => {
    resolver = resolve;
    rejecter = reject;
  });

  return [
    promise,
    {
      // eslint-disable-next-line
      // @ts-ignore
      resolver,
      // eslint-disable-next-line
      // @ts-ignore
      rejecter,
    },
  ];
};

const isString = (item: unknown): item is string => typeof item === 'string';

const isArrayOfStrings = (unknown: unknown): unknown is string[] => Array.isArray(unknown) && unknown.every(isString);

const toStringListOrEmpty = (input: unknown): string[] => (isString(input) ? [input] : []);

const resolveMerchantIds = (merchantIds: string | string[]): string[] =>
  isArrayOfStrings(merchantIds) ? merchantIds : toStringListOrEmpty(merchantIds);

const buildMerchantList = (merchantId?: string, merchantIds?: string[]): string[] =>
  [...resolveMerchantIds(merchantId), ...resolveMerchantIds(merchantIds)].filter(Boolean);

export type InitParams = {
  widgetId: string;
  /**
   * @deprecated use `merchantIds` instead
   */
  merchantId?: string;
  merchantIds?: string[];
  autoShow?: boolean;
  containerSelector?: string;
  widgetVersion: string;
};

type WidgetState = {
  autoShow?: boolean;
  alreadySentInit: boolean;
  paramsSet: boolean;
  widgetId: string | null;
  merchantId: string[] | null;
  currentVersion: string | undefined;
};

const MAX_MERCHANTS = 10;

const V2_TAG = 'v2';

const initWidget = () => {
  const frame = setupIFrame();

  const state: WidgetState = {
    alreadySentInit: false,
    paramsSet: false,
    widgetId: null,
    merchantId: null,
    currentVersion: undefined,
  };

  document.head.appendChild(setupStyles());

  const [onInit, instantiatedPromise] = deferredPromise();

  const { leaveFocus, receiveCommands, workspace, setFallbackEnabled } = createHitRegionManager(frame);

  let onResize: () => void | null = null;

  const onFinishInit = (appVersion: string) => {
    const currentVersion = V2_TAG === appVersion ? V2_TAG : undefined;

    state.currentVersion = currentVersion;

    setFallbackEnabled(!currentVersion);

    state.alreadySentInit = true;
  };

  const onConnect = () => {
    if (state.alreadySentInit) {
      channel
        .request('init', {
          widgetId: state.widgetId,
          merchantId: state.merchantId,
          widgetVersion: version,
        })
        .then((appVersion: string) => {
          onFinishInit(appVersion);
        })
        .catch(console.error);

      onResize();
    }
  };

  const channel = new IMessageChannelPortSender(frame, WIDGET_URL, onConnect);

  initAudioHost(channel);

  channel.addMessageListener('update-hit-regions', async (frames: CommandFrame[]) => {
    receiveCommands(frames);
  });

  onResize = () => {
    channel
      .request('resize', {
        width: window.innerWidth,
        height: window.innerHeight,
      })
      .catch(console.error);
  };

  channel.addMessageListener('set-css', async (data: string[][]) => {
    data.forEach(([property, value]) => {
      workspace.style.setProperty(property, value);
    });
  });

  channel.addMessageListener('set-placement', async (placement: string) => {
    setPlacement(placement, workspace);
  });

  channel.addMessageListener('leave-focus', async () => {
    leaveFocus();
  });

  channel.ready.then(async () => {
    instantiatedPromise.resolver();
    onConnect();
  });

  const show = () => {
    workspace.style.display = 'block';
  };

  const hide = () => {
    workspace.style.display = 'none';
  };

  const init = async (params: InitParams) => {
    const errorPrefix = 'Cannot initialize iFood Embeddables Widget:';

    if (!params) {
      throw new Error(`${errorPrefix} Missing widget params on init()`);
    }

    const container = params.containerSelector ? document.querySelector(params.containerSelector) : document.body;

    if (!container) {
      throw new Error(`${errorPrefix} Cannot find any elements matching the selector "${params.containerSelector}"`);
    }

    workspace.style.display = 'none';

    container.appendChild(workspace);

    const merchantList = buildMerchantList(params.merchantId, params.merchantIds);

    if (merchantList.length > MAX_MERCHANTS) {
      throw new Error(`Currently only ${MAX_MERCHANTS} merchant IDs can be used at the same time on the iFood Widget.`);
    }

    // Any other validations on merchantId and widgetId are handled by the widget since
    // they need to be displayed on the UI

    state.merchantId = merchantList;
    state.widgetId = params.widgetId;
    state.paramsSet = true;
    state.autoShow = params.autoShow ?? true;

    await onInit;

    window.addEventListener('resize', onResize);
    onResize();

    const appVersion = await channel.request('init', {
      widgetId: state.widgetId,
      merchantId: state.merchantId,
      widgetVersion: version,
    });

    onFinishInit(String(appVersion));

    if (state.autoShow) {
      show();
    }
  };

  const ready = onInit;

  return {
    show,
    hide,
    init,
    ready,
  };
};

export default initWidget;
