import { RequestProcessor } from './messaging';
import { IRequest, IResponse, MessageType, Status } from './types';

const SEND_PORT_EVENT = '__IFOOD_MESSAGING_SEND_PORT_EVENT__';

const isValidMessage = (message: MessageEvent, type: MessageType) => {
  const hasDataField = Object.prototype.hasOwnProperty.call(message.data, 'data');
  const hasKey = typeof message.data.key == 'string';
  const hasId = typeof message.data.id === 'number';
  const hasType = message.data.type === type;
  const hasStatus =
    message.data.type === MessageType.REQUEST ||
    message.data.status === Status.SUCCESS ||
    message.data.status === Status.ERROR;

  const is = hasDataField && hasKey && hasId && hasStatus && hasType;

  return is;
};

export class IMessageChannelPortSender extends RequestProcessor {
  private channel: MessageChannel | undefined;

  /**
   * `IMessageChannelPortSender` implements `RequestProcessor` using the [MessageChannel API](https://developer.mozilla.org/en-US/docs/Web/API/Channel_Messaging_API)
   * The port sender variant is intended to be used on the top frame.
   *
   * It also handles window refresh of the target iframe.
   *
   * @param target The target element (probably an iFrame) the message port will be sent to.
   */
  constructor(target: HTMLIFrameElement, targetOrigin: string, reconnect?: () => void) {
    super();

    target.addEventListener('load', () => {
      if (this.channel) {
        this.channel.port1.close();
        this.channel.port2.close();
      }

      this.channel = new MessageChannel();

      this.channel.port1.onmessage = (message) => {
        this.onMessage(message);
      };

      target.contentWindow?.postMessage(SEND_PORT_EVENT, targetOrigin, [this.channel.port2]);

      setTimeout(() => {
        if (this.channel) {
          reconnect?.();
        }
      }, 0);
    });
  }

  protected parseRequest(message: MessageEvent): IRequest {
    return message.data;
  }

  protected parseResponse(message: MessageEvent): IResponse {
    return message.data;
  }

  protected isRequest(message: MessageEvent): boolean {
    return isValidMessage(message, MessageType.REQUEST);
  }

  protected isResponse(message: MessageEvent): boolean {
    return isValidMessage(message, MessageType.RESPONSE);
  }

  protected emitRequest(request: IRequest): void {
    if (this.channel) {
      this.channel?.port1.postMessage(request);
    } else {
      throw new Error('Cannot send message in this channel, handshake not complete yet.');
    }
  }

  protected emitResponse(message: IResponse): void {
    if (this.channel) {
      this.channel?.port1.postMessage(message);
    } else {
      throw new Error('Cannot send message in this channel, handshake not complete yet.');
    }
  }
}

export class BCMessageChannel extends RequestProcessor {
  private readonly channel: BroadcastChannel;

  /**
   * `BCMessageChannel` implements `RequestProcessor` using the [Broadcast Channel API](https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API)
   * It's recommended to only create pairs of `BCMessageChannels` (the the argument being called ID) to respect the RPC style communication, since the native API **broadcasts**
   * the messages to all channels with the same ID, and having more than 2 so would result in warnings about responses for unsolicited requests.
   *
   * This implementation of `RequestProcessor` is completely symmetric, so there is only one implementation.
   * The one initialized last will complete the handshake process.
   *
   * @param id - The ID of the channel
   */
  constructor(id: string) {
    super();

    this.channel = new BroadcastChannel(id);

    this.channel.addEventListener('message', (event) => {
      this.onMessage(event);
    });

    this.tryHandshake();
  }

  protected parseRequest(message: MessageEvent): IRequest {
    return message.data;
  }

  protected parseResponse(message: MessageEvent): IResponse {
    return message.data;
  }

  protected isRequest(message: MessageEvent): boolean {
    return isValidMessage(message, MessageType.REQUEST);
  }

  protected isResponse(message: MessageEvent): boolean {
    return isValidMessage(message, MessageType.RESPONSE);
  }

  protected emitRequest(request: IRequest): void {
    this.channel?.postMessage(request);
  }

  protected emitResponse(message: IResponse): void {
    this.channel?.postMessage(message);
  }
}

export class IMessageChannelPortReceiver extends RequestProcessor {
  private port?: MessagePort;

  /**
   * `IMessageChannelPortReceiver` implements `RequestProcessor` using the [MessageChannel API](https://developer.mozilla.org/en-US/docs/Web/API/Channel_Messaging_API)
   * The port receiver variant is intended to be used on child frames, it will attempt to do the handshake process upon instantiation.
   *
   * @param port The message port this instance will listen to.
   */
  constructor(targetOrigin: string | null) {
    super();

    if (targetOrigin) {
      window.addEventListener('message', (event) => {
        if (event.origin === targetOrigin) {
          if (event.data === SEND_PORT_EVENT) {
            const [port] = event.ports;

            this.port = port;

            this.tryHandshake();

            port.onmessage = (message) => {
              this.onMessage(message);
            };
          }
        }
      });
    } else {
      console.log(
        '[IMessageChannelPortReceiver] No `targetOrigin` set, message requests to this channel will never resolve.'
      );
    }
  }

  protected parseRequest(message: MessageEvent): IRequest {
    return message.data;
  }

  protected parseResponse(message: MessageEvent): IResponse {
    return message.data;
  }

  protected isRequest(message: MessageEvent): boolean {
    return isValidMessage(message, MessageType.REQUEST);
  }

  protected isResponse(message: MessageEvent): boolean {
    return isValidMessage(message, MessageType.RESPONSE);
  }

  protected emitRequest(request: IRequest): void {
    this.port?.postMessage(request);
  }

  protected emitResponse(message: IResponse): void {
    this.port?.postMessage(message);
  }
}
