import { G } from '@mobily/ts-belt';
import { format, isValid } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { atomWithStorage as jotaiAtomWithStorage } from 'jotai/utils';
import { useEffect, useRef } from 'react';

export function formatUTCDate(date: string): string {
  const newDate: Date = new Date(date);
  return format(newDate, 'P • pp', { locale: ptBR });
}

export function formatDate(date: string): string {
  const newDate: Date = new Date(date);
  if (isValid(newDate)) {
    return format(newDate, 'P', { locale: ptBR });
  }

  return '';
}

export function formatTime(date: string): string {
  const newDate: Date = new Date(date);

  if (isValid(newDate)) {
    return format(newDate, 'pp', { locale: ptBR });
  }

  return '';
}

export function formatDateWithDayDotTime(date: Date): string {
  const newDate: Date = new Date(date);
  if (isValid(newDate)) {
    return format(date, 'P • pp', { locale: ptBR });
  }

  return '';
}

export const formatOrderNumber = (orderNumber?: string): string => {
  return orderNumber ? orderNumber.slice(-4) : '';
};

export const getFilterChanges = <T extends Record<string, unknown>>(
  filter: T,
  defaults: Record<keyof T, unknown>
): number => {
  const changes = Object.entries(filter).filter(([key, value]) => {
    const item = defaults[key];
    if (Array.isArray(value)) {
      return value.length !== 0;
    }
    return item !== value;
  });

  const filteredChanges = changes.filter((change) => change[0] !== 'orderBy' && change[0] !== 'query');

  return filteredChanges.length;
};

type DeferredPromiseBuild<T> = [
  Promise<T>,
  {
    resolver: (value: T | PromiseLike<T>) => void;
    rejecter: (reason?: unknown) => void;
  }
];

export const deferredPromise = <T = void>(): DeferredPromiseBuild<T> => {
  let resolver: (value: T | PromiseLike<T>) => void;
  let rejecter: (reason?: unknown) => void;

  const promise = new Promise<T>((resolve, reject) => {
    resolver = resolve;
    rejecter = reject;
  });

  return [
    promise,
    {
      // eslint-disable-next-line
      // @ts-ignore
      resolver,
      // eslint-disable-next-line
      // @ts-ignore
      rejecter,
    },
  ];
};

export function shadeColor(color: string, amount: number) {
  return (
    '#' +
    color
      .replace(/^#/, '')
      .replace(/../g, (color) =>
        ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
      )
  );
}

type CurrencyFormatOptions = {
  locale?: string;
  style?: string;
  currency?: string;
};

export function formatCurrency(value?: number, options?: CurrencyFormatOptions) {
  const { locale, style, currency } = {
    locale: 'pt-BR',
    style: 'currency',
    currency: 'BRL',
    ...options,
  };

  return value ? new Intl.NumberFormat(locale, { style, currency }).format(value) : '';
}

export const isUUID = (maybeUUID: unknown): maybeUUID is string => {
  if (G.isString(maybeUUID)) {
    return /^[0-9A-Z]{8}-[0-9A-Z]{4}-[0-9A-Z]{4}-[0-9A-Z]{4}-[0-9A-Z]{12}$/i.test(maybeUUID);
  }

  return false;
};

const FAKE_LOCAL_STORAGE: Record<string, string> = {};

let localStorageTest: undefined | boolean = undefined;

export const hasIndexedDB = (): boolean => {
  try {
    return 'indexedDB' in window;
  } catch (error) {
    return false;
  }
};

export const hasLocalStorage = (): boolean => {
  // Don't recompute this every time.
  if (localStorageTest !== undefined) {
    return localStorageTest;
  }

  // Cross origin iFrames on anonymous windows
  // throw errors when running the `window.localStorage` getter.
  try {
    void window.localStorage;
  } catch (error) {
    localStorageTest = false;
    return localStorageTest;
  }

  // Test if the API exists and works as expected
  // It might throw errors if we (or dependencies)
  // exceeded the storage quota.
  try {
    const value = Math.random().toString();
    const key = 'local-storage-test';
    window.localStorage.setItem(key, value);
    const stored = window.localStorage.getItem(key);

    if (stored === value) {
      window.localStorage.removeItem(key);
    } else {
      throw new Error('Value is different');
    }
  } catch (error) {
    localStorageTest = false;
    return localStorageTest;
  }

  localStorageTest = true;
  return localStorageTest;
};

export const localStorageGetItem: Storage['getItem'] = (key) => {
  if (hasLocalStorage()) {
    return window.localStorage.getItem(key);
  }

  return FAKE_LOCAL_STORAGE[key] ?? null;
};

export const localStorageSetItem: Storage['setItem'] = (key, value) => {
  if (hasLocalStorage()) {
    window.localStorage.setItem(key, value);
    return;
  }

  FAKE_LOCAL_STORAGE[key] = value;
};

export const localStorageRemoveItem: Storage['removeItem'] = (key) => {
  if (hasLocalStorage()) {
    window.localStorage.removeItem(key);
    return;
  }

  delete FAKE_LOCAL_STORAGE[key];
};

const FAKE_SESSION_STORAGE: Record<string, string> = {};

let sessionStorageTest: undefined | boolean = undefined;

const hasSessionStorage = (): boolean => {
  // Don't recompute this every time.
  if (sessionStorageTest !== undefined) {
    return sessionStorageTest;
  }

  // Cross origin iFrames on anonymous windows
  // throw errors when running the `window.sessionStorage` getter.
  try {
    void window.sessionStorage;
  } catch (error) {
    sessionStorageTest = false;
    return sessionStorageTest;
  }

  // Test if the API exists and works as expected
  // It might throw errors if we (or dependencies)
  // exceeded the storage quota.
  try {
    const value = Math.random().toString();
    const key = 'session-storage-test';
    window.sessionStorage.setItem(key, value);
    const stored = window.sessionStorage.getItem(key);

    if (stored === value) {
      window.sessionStorage.removeItem(key);
    } else {
      throw new Error('Value is different');
    }
  } catch (error) {
    sessionStorageTest = false;
    return sessionStorageTest;
  }

  sessionStorageTest = true;
  return sessionStorageTest;
};

export const sessionStorageGetItem: Storage['getItem'] = (key) => {
  if (hasSessionStorage()) {
    return window.sessionStorage.getItem(key);
  }

  return FAKE_SESSION_STORAGE[key] ?? null;
};

export const sessionStorageSetItem: Storage['setItem'] = (key, value) => {
  if (hasSessionStorage()) {
    window.sessionStorage.setItem(key, value);
    return;
  }

  FAKE_SESSION_STORAGE[key] = value;
};

export const sessionStorageRemoveItem: Storage['removeItem'] = (key) => {
  if (hasSessionStorage()) {
    window.sessionStorage.removeItem(key);
    return;
  }

  delete FAKE_SESSION_STORAGE[key];
};

export const atomWithStorage = <T>(key: string, initial: T) => {
  if (!localStorageGetItem(key)) {
    localStorageSetItem(key, JSON.stringify(initial));
  }

  return jotaiAtomWithStorage<T>(key, initial);
};

export const useEmitter = <T>(emitter: Emitter<T>, callbackRef?: (value: T) => void): ((value: T) => void) => {
  const callback = useRef<(value: T) => void>();
  callback.current = callbackRef;

  useEffect(() => {
    if (callback.current) {
      return emitter.listen(callback.current);
    }
    return;
  }, []);

  return emitter.emit;
};

export type UnListenCb = () => void;

export type Emitter<T> = {
  listen: (callback: (value: T) => void) => UnListenCb;
  emit: (value: T) => void;
};

export const emitter = <T>(): Emitter<T> => {
  const listeners = new Set<(value: T) => void>();

  const listen = (callback: (value: T) => void): (() => void) => {
    listeners.add(callback);

    return () => listeners.delete(callback);
  };

  const emit = (value: T) => {
    setTimeout(() => {
      listeners.forEach((fn) => fn(value));
    }, 0);
  };

  return { listen, emit };
};

export const delay = (ms = 1_000) => new Promise((res) => setTimeout(res, ms));

export const formatError = (error: unknown) => (error instanceof Error ? error.message : String(error));
