import { localStorageGetItem } from '@embeddables/shared/utils';

export type HitRegionBox = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type CommandFrame = {
  id: string;
} & HitRegionBox;

export type CommandConsumer = (commands: CommandFrame[]) => void;

type HitRegionManager = {
  workspace: HTMLDivElement;
  receiveCommands: CommandConsumer;
  leaveFocus: () => void;
  setFallbackEnabled: (enabled: boolean) => void;
};

const DEBUG = localStorageGetItem('hitRegionDebug');

export const createHitRegionManager = (managed: HTMLIFrameElement): HitRegionManager => {
  const HitRegions = new Map<string, HTMLElement>();

  const styles = document.createElement('style');

  const styleId = 'embeddables-hit-test-manager';

  if (!document.getElementById(styleId)) {
    styles.id = styleId;
    styles.innerHTML = `
    .click-rect {
      position: fixed;
      pointer-events: all;
      ${DEBUG ? `background-color: rgba(0, 0, 255, 0.2); border: 1px solid black;` : ''}
    }

    ${
      DEBUG
        ? `
      .click-rect:hover {
        cursor: not-allowed;
        background-color: rgba(0, 0, 255, 0.5);
      }
    `
        : ''
    }

    .embeddables-focus-wrapper.focused .click-rect {
      pointer-events: none;
      ${DEBUG ? 'background-color: rgba(0, 255, 0, 0.2);' : ''}
    }

    .embeddables-focus-wrapper {
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100vw;
      height: 100vh;
      pointer-events: none;
      z-index: 2147483641;
      ${DEBUG ? 'background-color: rgba(0, 0, 0, 0.2);' : ''}
    }

    .embeddables-focus-wrapper iframe {
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100vh;
      pointer-events: none;
    }



    ${'' /* It's safe to enable pointer-events here, since on fallback mode, the widget is never fullscreen*/}
    .embeddables-focus-wrapper.with-fallback {
      pointer-events: initial;
    }

    .embeddables-focus-wrapper.with-fallback iframe {
      position: initial;
      width: 100%;
      height: 100%;
      pointer-events: initial;
    }
  `;

    document.head.append(styles);
  }

  const workspace = document.createElement('div');
  workspace.className = 'embeddables-focus-wrapper';

  workspace.append(managed);

  const enterFocus = () => {
    workspace.classList.add('focused');
    managed.style.pointerEvents = 'all';
  };

  const leaveFocus = () => {
    workspace.classList.remove('focused');
    managed.style.pointerEvents = 'none';
  };

  const reconcileRegions = (commands: CommandFrame[]) => {
    const hitRegionsToRemove = new Set(HitRegions.keys());

    for (const command of commands) {
      if (HitRegions.has(command.id)) {
        hitRegionsToRemove.delete(command.id);

        const element = HitRegions.get(command.id);
        if (!element) {
          console.error(`Missing click-rect id "${command.id}"`);
          continue;
        }

        element.style.width = `${command.width}px`;
        element.style.height = `${command.height}px`;
        element.style.top = `${command.y}px`;
        element.style.left = `${command.x}px`;
      } else {
        const element = document.createElement('div');
        HitRegions.set(command.id, element);

        element.setAttribute('data-embdd-hit-region-id', command.id);
        element.className = 'click-rect';
        element.style.width = `${command.width}px`;
        element.style.height = `${command.height}px`;
        element.style.top = `${command.y}px`;
        element.style.left = `${command.x}px`;

        element.addEventListener('mouseenter', () => {
          enterFocus();
        });

        workspace.appendChild(element);
      }
    }

    Array.from(hitRegionsToRemove.values()).forEach((id) => {
      const element = HitRegions.get(id);
      element!.remove();
      HitRegions.delete(id);
    });
  };

  const setFallbackEnabled = (fallback: boolean) => {
    if (fallback) {
      workspace.classList.add('with-fallback');
    } else {
      workspace.classList.remove('with-fallback');
    }
  };

  return {
    workspace,
    receiveCommands: reconcileRegions,
    leaveFocus,
    setFallbackEnabled,
  };
};
