import { IRequest, IResponse, MessageHandler, MessageType, PendingMessage, Status } from './types';

const HANDSHAKE_REQUEST = '__HANDSHAKE__';
const HANDSHAKE_PENDING_ID = -1;

/**
 * `RequestProcessor` Implements a system that brings a request/response model for arbitrary event-based
 *  communication systems (Like browsers `postMessage` API).
 */
export abstract class RequestProcessor {
  private handshakeComplete = false;
  private messageId = 0;
  private readonly handlers: Map<string, MessageHandler>;
  private readonly pendingMessages: Map<number, PendingMessage> = new Map();
  private connect?: () => void;

  /**
   * Ready promise, will be resolved when the connection with another instance of `RequestProcessor`
   * has been established.
   *
   * Implementations of `RequestProcessor` should call the `tryHandshake` method in order
   * to trigger detection of connectivity.
   */
  ready: Promise<void>;

  constructor() {
    this.ready = new Promise((res) => {
      this.connect = res;
    });

    this.handlers = new Map();

    this.handlers.set(HANDSHAKE_REQUEST, async () => {
      this.connect?.();
    });
  }

  private getNextMessageId() {
    this.messageId++;
    return this.messageId;
  }

  private static buildResponse(status: Status, key: string, id: number, data: unknown): IResponse {
    return {
      status,
      key,
      id,
      data,
      type: MessageType.RESPONSE,
    };
  }

  private static buildRequest(key: string, id: number, data: unknown): IRequest {
    return {
      key,
      id,
      data,
      type: MessageType.REQUEST,
    };
  }

  private handleRequest(request: IRequest) {
    const { key, data, id } = request;

    const handler = this.handlers.get(key);

    if (!handler) {
      this.emitResponse(
        RequestProcessor.buildResponse(
          Status.ERROR,
          key,
          id,
          `No message handler for ${key} registered yet, rejecting message.`
        )
      );
    } else {
      // This RequestProcessor instance did the setup first so the handshake will never be resolved.
      if (key === HANDSHAKE_REQUEST) {
        this.pendingMessages.delete(HANDSHAKE_PENDING_ID);
      }

      handler(data)
        .then((response) => {
          this.emitResponse(RequestProcessor.buildResponse(Status.SUCCESS, key, id, response));
        })
        .catch((error) => {
          this.emitResponse(RequestProcessor.buildResponse(Status.ERROR, key, id, String(error)));
        });
    }
  }

  private handleResponse(response: IResponse) {
    const { key, data, status, id } = response;

    const pendingMessage = this.pendingMessages.get(id);

    if (!pendingMessage) {
      console.error(`Got response for message with name ${key}, but no pending message was found`);
    } else {
      this.pendingMessages.delete(id);

      const [resolve, reject] = pendingMessage;

      if (status === Status.SUCCESS) {
        resolve(data);
      } else {
        reject(data);
      }
    }
  }

  /**
   * Entry point for all events, usually attached to `addEventListener` or `onmessage` as callback.
   */
  onMessage(message: unknown) {
    if (this.isRequest(message)) {
      this.handleRequest(this.parseRequest(message));
    } else if (this.isResponse(message)) {
      this.handleResponse(this.parseResponse(message));
    }
  }

  /**
   * Registers an event handler for the given event name.
   * @param name - The name of the event.
   * @param handler - Callback to handle the event, the returned value from the promise will be sent back as the response.
   */
  addMessageListener(name: string, handler: MessageHandler) {
    this.handlers.set(name, handler);
  }

  /**
   * Sends an request to the target.
   * @param name - The name of the event.
   * @param message - The message payload, this will be passed as the argument for the message handler.
   * @returns A promise that is resolved then the other end receives and processes the message, the resolved value is the value returned from the message handler.
   */
  request(name: string, message: unknown): Promise<unknown> {
    return new Promise((resolve, reject) => {
      const id = name === HANDSHAKE_REQUEST ? HANDSHAKE_PENDING_ID : this.getNextMessageId();

      this.pendingMessages.set(id, [(data) => resolve(data), reject]);

      // Handshake message always goes through
      // Any other messages are queued and only sent if
      // the channel is ready.
      if (name === HANDSHAKE_REQUEST) {
        this.emitRequest(RequestProcessor.buildRequest(name, id, message));
      } else {
        this.ready.then(() => {
          this.emitRequest(RequestProcessor.buildRequest(name, id, message));
        });
      }
    });
  }

  /**
   * Tries to detect if another `RequestProcessor` instance is reachable.
   * How both are connected depends on implementations of `RequestProcessor`
   *
   * This can be called in both ends at any time.
   */
  tryHandshake() {
    if (!this.handshakeComplete) {
      this.request(HANDSHAKE_REQUEST, undefined)
        .then(() => {
          this.connect?.();
          this.handshakeComplete = true;
        })
        .catch(() => {
          // Safe if failed
        });
    }
  }

  /**
   * Implementations should parse the event into a `IRequest` object.
   * @param message - The message received.
   */
  protected abstract parseRequest(message: unknown): IRequest;

  /**
   * Implementations should parse the event into a `IResponse` object.
   * @param message - The message received.
   */
  protected abstract parseResponse(message: unknown): IResponse;

  /**
   * Implementations should return true if the message received is a `IRequest` object.
   * @param message - The message received.
   */
  protected abstract isRequest(message: unknown): boolean;

  /**
   * Implementations should return true if the message received is a `IResponse` object.
   * @param message - The message received.
   */
  protected abstract isResponse(message: unknown): boolean;

  /**
   * Implementation of how a request event is sent to the target.
   * @param message - The message to send.
   */
  protected abstract emitRequest(message: IRequest): void;

  /**
   * Implementation of how a response event is sent to the target.
   * @param message - The message to send.
   */
  protected abstract emitResponse(message: IResponse): void;
}
